import { AssetCategory, AssetCategoryPathList } from '@/shared/types/assets/assets.types.ts';

export enum CategoryTypeEnum {
  Equipment = 'site/Equipment',
  Worker = 'site/Worker',
}

export type FindCategoryProps = AssetCategory | undefined;

export type FindCategoryByTypeProps = AssetCategory | null;

export type RenderCategoryMethodsProps = {
  isEquipment: () => boolean;
  isWorker: () => boolean;
};

export type GetCategoryProps = (FindCategoryProps & RenderCategoryMethodsProps) | null;

export type GetTransformedCategoryProps = null | (TransformedCategory & RenderCategoryMethodsProps);

export type GetSubCategoryProps = AssetCategoryPathList | null | undefined;

export type TransformedCategoryPathItem = Pick<AssetCategoryPathList, 'extended'> & {
  categoryStatus: AssetCategoryPathList['categoryStatus'];
  id: string;
  name: string;
};

export type TransformedCategory = Pick<
  AssetCategory,
  'categoryTreeName' | 'categoryTreeResName' | 'categoryTreeStatus' | 'extended'
> & {
  categoryPath: string;
  categoryPathItems: TransformedCategoryPathItem[];
  categoryPathResName: string;
};
